import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom';
import { Grid, Header, Image, Message, Segment, Icon, Step } from 'semantic-ui-react'
import Brand from './resources/logo.png';
import { connect } from 'react-redux';

import { verifyMal } from './store/actions/checkMalActions'

class Check extends Component {

    componentDidMount() {
        this.props.verifyMal(this.props.match.params.code)
    }

    render() {

        const { processing, completed, error, errorMsg, errorMsgData, verificationData } = this.props.verifyMalData;

        const { tenderName, tenderRefNo, companyName } = verificationData;

        return (
            <div className='login-form'>
                {/*
      Heads up! The styles below are necessary for the correct render of this example.
      You can do same with CSS, the main idea is that all the elements up to the `Grid`
      below must have a height of 100%.
    */}
                <style>{`
      body > div,
      body > div > div,
      body > div > div > div.login-form {
        height: 100%;
      }
    `}</style>
                <Grid centered style={{ height: '100%' }} verticalAlign='middle'>
                    <Grid.Column style={{ maxWidth: 450 }}>

                        <Grid textAlign='center'>
                            <Grid.Row>
                                <Image src={Brand} size='small' centered />
                                <Header as='h3' textAlign='center'>
                                    Manufacture Authorization Letter Verification
                                    <Header.Subheader>
                                        Hikvision Sri Lanka
                                    </Header.Subheader>
                                </Header>


                            </Grid.Row>
                        </Grid>

                        {
                            error &&
                            <Message negative fluid>
                                <Message.Header>Error</Message.Header>
                                <p>{errorMsg ? errorMsg : ''}</p>
                                <p>{errorMsgData ? errorMsgData.toString() : ''}</p>
                            </Message>
                        }


                        <Grid.Row textAlign='left'>

                            {
                                !error &&
                                <Segment stacked loading={processing} style={{ marginTop: '10px' }}>
                                    {
                                        processing &&
                                        <h3 textAlign='center'>Processing.</h3>
                                    }

                                    {
                                        completed &&
                                        <Fragment>
                                            <Grid textAlign='center' style={{ marginTop: '10px' }}>
                                                <Icon circular inverted color='teal' name='check' size='big' />

                                                <h4 textAlign='center' style={{ marginBottom: '10px' }}>This is a genuine manufacture authorization letter.</h4>
                                            </Grid>

                                            <Step.Group vertical fluid>
                                                <Step completed>
                                                    <Icon name='truck' />
                                                    <Step.Content>
                                                        <Step.Title>Tender Name</Step.Title>
                                                        <Step.Description>{tenderName}</Step.Description>
                                                    </Step.Content>
                                                </Step>

                                                <Step completed>
                                                    <Icon name='payment' />
                                                    <Step.Content>
                                                        <Step.Title>Tender Reference Number</Step.Title>
                                                        <Step.Description>{tenderRefNo}</Step.Description>
                                                    </Step.Content>
                                                </Step>

                                                <Step completed>
                                                    <Icon name='payment' />
                                                    <Step.Content>
                                                        <Step.Title>Letter Issued To</Step.Title>
                                                        <Step.Description>{companyName}</Step.Description>
                                                    </Step.Content>
                                                </Step>
                                            </Step.Group>
                                            {
                                                completed &&
                                                <Grid textAlign='center'>
                                                    <Message style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                        Check another <Link to='/'>MAL?</Link>
                                                    </Message>
                                                </Grid>

                                            }

                                        </Fragment>
                                    }

                                </Segment>
                            }



                        </Grid.Row>
                        <Grid.Row>
                            <Grid textAlign='center'>


                                {
                                    error &&
                                    <Message style={{ marginTop: '10px', marginBottom: '10px' }}>
                                        Do you want to <Link to='/'>retry?</Link>
                                    </Message>
                                }

                            </Grid>
                        </Grid.Row>
                    </Grid.Column>



                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        verifyMalData: state.verifyMal
    }
}


export default connect(mapStateToProps, {
    verifyMal
})(Check)