import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';
import { Button, Form, Grid, Header, Image, Segment } from 'semantic-ui-react'
import Brand from './resources/logo.png';

class Home extends Component {
  state = {
    code: '',
    submitted: false
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }


  handleCodeSubmit = (e) => {
    this.setState({submitted: true})
  }

  render() {



    const { code, submitted } = this.state;

    if(submitted){
      return <Redirect to={`/${code}`} />
    }

    return (
      <div className='login-form'>
        {/*
      Heads up! The styles below are necessary for the correct render of this example.
      You can do same with CSS, the main idea is that all the elements up to the `Grid`
      below must have a height of 100%.
    */}
        <style>{`
      body > div,
      body > div > div,
      body > div > div > div.login-form {
        height: 100%;
      }
    `}</style>
        <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
          <Grid.Column style={{ maxWidth: 450 }}>
            <Image src={Brand} size='small' centered />
            <Header as='h3'>
              Manufacture Authorization Letter Verification
              <Header.Subheader>
                Hikvision Sri Lanka
              </Header.Subheader>
            </Header>

            <Segment stacked>

              <Form size='large' onSubmit={this.handleCodeSubmit}>

              <p>Please enter the verification code mentioned in the Manufacture Authorization Letter (MAL)</p>
                <Form.Input
                  fluid icon='ellipsis horizontal'
                  iconPosition='left'
                  placeholder='Verification Code'
                  id="code"
                  value={code}
                  onChange={this.handleChange}
                  required
                />
                {
                  code &&
                  <Button color='blue' fluid size='large'>
                    Submit
                    </Button>
                }


              </Form>
            
            </Segment>

            {/* <Message>
              New to us? <Link to='/signup'>Sign up</Link>
            </Message> */}
          </Grid.Column>
        </Grid>
      </div>
    )
  }
}

export default Home;