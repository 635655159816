export const verifyMal = (id) => {
    return (dispatch, getState, { getFirestore }) => {
        dispatch({ type: 'MAL_VERIFICATION_STARTED' });

        getFirestore().collection('malverify').doc(id).get()
            .then(doc => {

                const malVerifyData = doc.data();
                if (malVerifyData) {
                    const { tenderName, tenderRefNo, partnerCompany } = malVerifyData;
                    
                    
                    getFirestore().collection('customers').doc(partnerCompany).get()
                    .then(companyData => {

                        const malVerifyDataProcessed= {
                            tenderName,
                            tenderRefNo,
                            companyName: companyData.data().companyName
                        }

                        console.log(malVerifyDataProcessed)

                        dispatch({
                            type: 'MAL_VERIFICATION_SUCCESS',
                            payload: malVerifyDataProcessed
                        })
                    })
                    .catch(err => {
                        dispatch({
                            type: 'MAL_VERIFICATION_FAILED',
                            payload: {
                                errorMsg: 'Error in getting company data. Please report this error to support@hik-portal.lk',
                                errorMsgData: err.toString()
                            }
                        })
                    })
                    
                } else {
                    dispatch({
                        type: 'MAL_VERIFICATION_FAILED',
                        payload: {
                            errorMsg: 'Invalid ID. Please check and retry.'
                        }
                    })
                }
            })
            .catch(err => {
                dispatch({
                    type: 'MAL_VERIFICATION_FAILED',
                    payload: {
                        errorMsg: 'Database connectivity failed. Please report this error to support@hik-portal.lk',
                        errorMsgData: err.toString()
                    }
                })
            })
    }
}

export const resetMalVerification = () => {
    return (dispatch, getState, { getFirestore})=> {
        dispatch({type: 'MAL_VERIFICATION_RESET'})
    }
}