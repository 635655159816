const initState = {
    processing: false,
    completed: false,
    error: false,
    errorMsg: '',
    errorMsgData: '',
    verificationData: ''
}

const verifyMalReducer = (state = initState, action) => {

    switch (action.type) {
        case 'MAL_VERIFICATION_STARTED':
            return {
                ...initState,
                processing: true
            }
        case 'MAL_VERIFICATION_SUCCESS':
            return {
                ...initState,
                completed: true,
                verificationData: action.payload
            }
        case 'MAL_VERIFICATION_FAILED':
            const { errorMsg, errorMsgData } = action.payload;
            return {
                ...initState,
                error: true,
                errorMsg,
                errorMsgData
            }
        case 'MAL_VERIFICATION_RESET':
            return initState
        default:
            return state;
    }
}

export default verifyMalReducer;